<template>
    <div class="container">
        <section class="section">
            <div class="text-start mb-5">
                <h1 class="fw-bold pb-3 border-bottom">FAQ</h1>
            </div>
            <div>
                <details class="text-start mb-4" v-for="(item,index) in faqList" :key="index">
                    <summary class="fw-bold mb-4 fs-3">Q. {{item.question}}</summary>
                    <div class="p-3 rounded-lg bg-white fs-5">{{item.answer}}</div>
                </details>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    data(){
        return{
            faqList:[
                {
                    question:'How do I buy Tokens? Is it safe?',
                    answer:'Our token is based on Binance Smart Chain and liquidity is locked in PancakeSwap. The ownership of contract has been transferred to the burn address. It is totally unruggab.'
                },
                {
                    question:'How do I buy Tokens? Is it safe?',
                    answer:'Our token is based on Binance Smart Chain and liquidity is locked in PancakeSwap. The ownership of contract has been transferred to the burn address. It is totally unruggab.'
                },
                {
                    question:'How do I buy Tokens? Is it safe?',
                    answer:'Our token is based on Binance Smart Chain and liquidity is locked in PancakeSwap. The ownership of contract has been transferred to the burn address. It is totally unruggab.'
                },
                {
                    question:'How do I buy Tokens? Is it safe?',
                    answer:'Our token is based on Binance Smart Chain and liquidity is locked in PancakeSwap. The ownership of contract has been transferred to the burn address. It is totally unruggab.'
                },
                {
                    question:'How do I buy Tokens? Is it safe?',
                    answer:'Our token is based on Binance Smart Chain and liquidity is locked in PancakeSwap. The ownership of contract has been transferred to the burn address. It is totally unruggab.'
                },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.section{
    margin-bottom: 120px;
}
.custom-table{
    .thead .tr{
        background-color: #eee;
    }
    .tr{
        display: flex;
        align-items: center;
        border-bottom: 1px solid #eee;
    }
    .td, .th{
        padding: 15px;
    }
}
.w-70{
    width: 70%;
}
</style>