<template>
    <div class="container">
        <section class="section">
            <div class="text-start mb-5">
                <h1 class="fw-bold pb-3 border-bottom">공지사항</h1>
            </div>
            <div class="custom-table bg-white rounded-lg shadow overflow-hidden">
                <div class="thead fw-bold">
                    <div class="tr">
                        <span class="th w-70">제목</span>
                        <span class="th">날짜</span>
                    </div>
                </div>
                <div class="tbody">
                    <div class="tr" v-for="(item,index) in notice" :key="index"
                        @click="$router.push(`/cs/notice/${index}`)"
                    >
                        <span class="td w-70 text-start">{{item.title}}</span>
                        <span class="td">{{item.date}}</span>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    data(){
        return{
            notice:[
                {
                    title:'공지사항',
                    date:'2020-01-28 14:13:00'
                },
                {
                    title:'고객센터 공지사항 생성',
                    date:'2020-01-28 14:13:00'
                },
                {
                    title:'NFT 마켓이 오픈하였습니다.',
                    date:'2020-01-28 14:13:00'
                },
                {
                    title:'공지사항',
                    date:'2020-01-28 14:13:00'
                },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.custom-table{
    .thead .tr{
        background-color: #eee;
    }
    .tr{
        display: flex;
        align-items: center;
        border-bottom: 1px solid #eee;
    }
    .td, .th{
        padding: 15px;
    }
}
.w-70{
    width: 70%;
}
@media screen and (max-width:768px) {
    .custom-table{
        .thead .tr{
            display: none;
        }
        .tr{
            flex-wrap: wrap;
            padding: 10px;
        }
        .td, .th{
            padding: 0 10px;
            width: 100%;
            text-align: left;
        }

        .td:nth-child(2){
            font-size: 80%;
            color: #aaa;
        }
    }
    
    .w-70{
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>