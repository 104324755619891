<template>
    <div class="container">
        <section class="section">
            <div class="text-start mb-5">
                <h1 class="fw-bold pb-3 border-bottom">1:1 문의</h1>
            </div>
            <div class="d-flex flex-column mb-4">
                <textarea name="" id="" cols="30" rows="10" class="rounded-lg p-3 mb-3 border-0 shadow"></textarea>
                <button class="py-2 rounded-pill bg-primary text-white">제출</button>
            </div>
            <p class="text-pale text-small">고객센터 응답 시간<br>AM 10:00 ~ PM 6:00</p>
        </section>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
button{
    width: 150px;
    margin: 0 auto;
}
</style>