<template>
    <div class="container">
        <div class="text-start mb-5">
            <h1 class="fw-bold pb-3 border-bottom">공지사항</h1>
        </div>
        <div class="card border-0 rounded-lg shadow">
            <div class="board_tit_box underline mb-4">
                <p class="tit">{{title}}</p>
                <p class="date">{{date}}</p>
            </div>
            <pre class="board_content_box">
                {{memo}}
            
            </pre>
        </div>
    </div>
</template>

<script>


export default {
    data(){
        return{
            title: 'Notice Title',
            date: '2022-02-02',
            memo: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur doloremque quo corporis accusamus molestiae dolor modi, facilis magni ratione voluptatum quis suscipit illum dignissimos sequi delectus dolorem temporibus fugit, impedit totam, rem assumenda est. Reiciendis enim eius itaque architecto deleniti deserunt unde accusantium soluta iure magnam ratione nulla, quam non voluptatum modi nisi asperiores! Quasi veniam eos nam voluptatum. Odit neque earum, expedita dignissimos quasi omnis voluptatum repudiandae asperiores saepe sed atque itaque. Adipisci expedita minus modi qui aliquam dolore architecto quisquam, quaerat, dolorum autem ratione ex facere consectetur praesentium dolor illum suscipit inventore omnis ipsa. Earum provident tenetur pariatur?',
        }
    },
    mounted(){
    },
    methods: {
    },
}
</script>
<style lang="scss" scoped>

.underline{
  border-bottom: 1px solid #eaeaea;
}

.board_tit_box{
  padding: 0.5em 1em;
  margin-bottom: 0;
}

.board_tit_box li{
  padding: 1em 0;
  border-bottom: 1px solid #eaeaea;

  /* padding: 1em;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14); */
}
.board_tit_box li:first-child{
  padding-top: 0;
}
.board_tit_box li:last-child{
  border-bottom: 0;
}
.board_tit_box a{
  color: #333;
}
.board_tit_box p{
  margin-bottom: 0;
}
.board_tit_box .tit{
  /* font-size: 15px; */
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
}
.board_tit_box a{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.board_tit_box a i{
  color: rgb(200,200,200);
}
.board_tit_box .date{
  /* font-size: 13px; */
  font-size: 0.9rem;
  color: #bbb;
  font-weight: 400;
  vertical-align: bottom;
}
.board_content_box{
  /* font-size: 14px; */
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.3;
  white-space: pre-line;
  font-family: inherit;
  margin-bottom: 0;
  padding: 1em;
}
</style>
